import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/api";
import { loading } from "./api/api";

export const store = configureStore({
  reducer: combineReducers({
    loading,
    [apiSlice.reducerPath]: apiSlice.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
