import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header/Header";
import { FaPlus } from "react-icons/fa6";
import { useGetStudentsQuery } from "../../Context/api/student";
import { Table } from "../../Components/Table/Table";
import { dateFormat } from "../../Utility/dateFormat";
import { PatternFormat } from "react-number-format";

export const Student = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { data = null } = useGetStudentsQuery();
  const students = data?.innerData || [];

  const filteredStudents = students?.filter((student) => {
    if (!search) return student;
    const fullname = student?.fullname?.toLowerCase();
    const phone = String(student?.student_phone).includes(search);
    return fullname?.includes(search) || phone;
  });

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">O'quvchilar ro'yxati</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate("/student/add")}>
            <FaPlus />
          </button>
        </div>
      </Header>

      <div
        className="page-container ff"
        style={{ display: !students?.length ? "none" : "flex" }}
      >
        <div className="search-form">
          <input
            type="search"
            placeholder="Ism Familiya, Telefon raqam"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {filteredStudents?.length ? (
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>F.I.O</th>
                <th>Telefon raqam</th>
                <th>Manzili</th>
                <th>Ro'yxatga o/v</th>
              </tr>
            </thead>

            <tbody>
              {filteredStudents?.map((student, index) => (
                <tr key={student.id}>
                  <td>{index + 1}</td>
                  <td onClick={() => navigate(`/student/${student.id}`)}>
                    {student.fullname}
                  </td>
                  <td>
                    <PatternFormat
                      format="+### ## ### ####"
                      allowEmptyFormatting
                      displayType="text"
                      mask=" "
                      value={student.student_phone}
                    />
                  </td>
                  <td>{student.address}</td>
                  <td>{dateFormat(student.joined_at, "date", "ru-RU")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="empty">
            <p>O'quvchi topilmadi</p>
          </div>
        )}
      </div>
    </div>
  );
};

// address: "Namangan, Uychi";
// balance: 0;
// birthday: "2004-05-12";
// contract_id: "8500d6";
// fullname: "Fizilhon Egamberdiyev";
// groups: '["e79b07"]';
// id: "edf6ad";
// joined_at: "2023-12-18T08:35:45.000Z";
// learning_center_id: "bc5525";
// parents_phone: 998902345678;
// source: "telegram";
// student: "he";
// student_phone: 998952345465;
// teacher: null;
// updated_at: "2023-12-18T08:35:45.000Z";
