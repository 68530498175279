import React from "react";
import { Link, useParams } from "react-router-dom";
import { useGetGroupQuery } from "../../Context/api/group";
import { Header } from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../Utility/dateFormat";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdModeEditOutline } from "react-icons/md";
import { Detail } from "../../Components/Detail/Detail";
import Skeleton from "react-loading-skeleton";
import { week } from "../../Utility/week";
import { Table } from "../../Components/Table/Table";
import { PatternFormat } from "react-number-format";
import { TbStatusChange } from "react-icons/tb";

export const GroupDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data = null, isLoading } = useGetGroupQuery(id);
  const group = data?.innerData || null;
  const students = data?.innerData?.students || null;

  const startDate = dateFormat(group?.started_at, "date", "ru-RU");
  const endDate = dateFormat(group?.finished_at, "date", "ru-RU");
  const betweenDate = `${startDate} - ${endDate}`;

  function teacher(group) {
    let text, url;
    url = `/teacher/${group?.teacher_id}`;
    if (group?.teacher) return <Link to={url}>{group?.teacher}</Link>;
    if (!group?.time) return <Skeleton width={150} />;
    url = "/teacher?add-teacher-to-group/group_id=" + group?.id;
    text = "O'qituvchi tayinlash";
    if (group?.time) return <Link to={url}>{text}</Link>;
  }

  function updateTeacher(group) {
    let url, update, icon;
    icon = <TbStatusChange />;
    url = "/teacher?add-teacher-to-group/group_id=" + group?.id;
    update = <Link to={url}>{icon}</Link>;
    if (group?.teacher) return update;
  }

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Ma'lumotlar</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate(`/group/update/${group?.id}`)}>
            <MdModeEditOutline />
          </button>
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container f6">
        <Detail>
          <h3>Gurux ma'lumotlari</h3>
          <p>
            <span>Guruh nomi</span>
            <b>{group?.group_name || <Skeleton width={200} />}</b>
          </p>
          <p>
            <span>O'qituvchi</span>
            <b>
              {teacher(group)} {updateTeacher(group)}
            </b>
          </p>

          <p>
            <span>Hafta kunlari</span>
            <b>{week(group?.days) || <Skeleton width={150} />}</b>
          </p>

          <p>
            <span>Kurs muddati</span>
            <b>{group?.started_at ? betweenDate : <Skeleton width={220} />}</b>
          </p>

          <p>
            <span>Dars vaqti</span>
            <b>{group?.time || <Skeleton width={120} />}</b>
          </p>

          <p>
            <span>Dars xonasi</span>
            <b>{group?.room || <Skeleton width={100} />}</b>
          </p>

          <p>
            <span>Gurux ochilgan sana</span>
            <b>
              {dateFormat(group?.created_at, "datetime", "ru-RU") || (
                <Skeleton width={170} />
              )}
            </b>
          </p>
          <p>
            <span>Oxirgi o'zgartirilgan sana</span>
            <b>
              {dateFormat(group?.updated_at, "datetime", "ru-RU") || (
                <Skeleton width={180} />
              )}
            </b>
          </p>

          <p>
            <span>Ma'lumot</span>
            <b>{group?.description || <Skeleton width={220} />}</b>
          </p>
        </Detail>

        <div
          className="page-container__box"
          style={{ display: isLoading ? "none" : "flex" }}
        >
          <h3>
            <span>
              {students?.length
                ? "Guruxdagi o'quvchilar"
                : "Hozircha guruxda o'quvchilar yo'q"}
            </span>
          </h3>
          <Table status={students?.length}>
            <thead>
              <tr>
                <th>#</th>
                <th>Ism Familiya</th>
                <th>Telefon raqam</th>
                <th>Ro'yxatdan o'/v</th>
                <th>Shartnoma</th>
              </tr>
            </thead>

            <tbody>
              {students?.map((student, index) => (
                <tr key={student.id}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/student/${student.id}`}>
                      {student.fullname}
                    </Link>
                  </td>
                  <td>
                    <PatternFormat
                      format="+### ## ### ####"
                      allowEmptyFormatting
                      displayType="text"
                      mask=" "
                      value={student.student_phone}
                    />
                  </td>

                  <td>{dateFormat(student.joined_at, "date", "ru-RU")}</td>
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/contract/${group.id}/${student.id}`}>
                      {student.contract_id ? "Ko'rish" : "Shartnoma"}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

// created_at: "2023-12-13T00:11:17.000Z";
// days: "[2,4]";
// description: "23r23r";
// finished_at: "2024-06-13T00:11:16.000Z";
// group_name: "23e23";
// id: "9d9091";
// learning_center_id: "b25ba4";
// room: "23r23r23";
// started_at: "2023-12-13T00:11:16.000Z";
// students: 0;
// teacher_id: null;
// time: "08:00 - 10:00";
// updated_at: "2023-12-13T00:11:17.000Z";
