import React, { useState, useLayoutEffect } from "react";
import "./assets/Auth.css";
import logo from "./assets/logo.svg";
import { MdPermPhoneMsg } from "react-icons/md";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar as EnSn } from "notistack";
import { useVerifyMutation } from "../../Context/api/auth";

export const Verification = () => {
  const navigate = useNavigate();
  const [sec, setSec] = useState(null);
  const exp = localStorage.getItem("exp") || 0;
  const [verify] = useVerifyMutation();

  useLayoutEffect(() => {
    const time = Math.floor((exp * 1000 - Date.now()) / 1000);
    if (time < 0) {
      localStorage.clear();
      navigate("/login", { replace: true });
    }
  }, [exp, navigate, sec]);

  setInterval(() => {
    setSec(Math.floor((exp * 1000 - Date.now()) / 1000));
  }, 1000);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const code = e.target.code.value;
    const { data, error } = await verify({ code });
    let message, variant;

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "Tizimga hush kelibsiz";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    localStorage.setItem("user", JSON.stringify(data?.innerData?.user) || null);
    localStorage.setItem("token", data?.innerData?.token || null);
    return navigate("/dashboard");
  };

  return (
    <div className="auth-page">
      <form className="auth-form" onSubmit={handleSubmit}>
        <img src={logo} alt="" />
        <h1>
          <span>Control Edu</span>
          <i>CRM boshqaruv tizimi </i>
        </h1>

        <p>{secundomer(sec)}</p>

        <label className="auth-label">
          <span>SMS Kod:</span>
          <PatternFormat
            format="####"
            allowEmptyFormatting
            mask="_"
            name="code"
            placeholder="Telefon raqam"
            autoComplete="off"
            required
            autoFocus
          />
        </label>
        <button className="auth-submit" type="submit">
          <MdPermPhoneMsg />
          <span>SMS Kodni tasdiqlash</span>
        </button>
      </form>
    </div>
  );
};

function secundomer(sec) {
  const min = Math.floor(sec / 60);
  const second = sec % 60 < 10 ? `0${sec % 60}` : sec % 60;
  return `${min}:${second}`;
}
