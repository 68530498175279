import React from "react";
import { Header } from "../../Components/Header/Header";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useGetStudentByIdQuery } from "../../Context/api/student";
import { Detail } from "../../Components/Detail/Detail";
import { MdModeEditOutline } from "react-icons/md";
import { dateFormat } from "../../Utility/dateFormat";
import { NumericFormat, PatternFormat } from "react-number-format";
import Skeleton from "react-loading-skeleton";
import { Table } from "../../Components/Table/Table";
import { PiContactlessPaymentLight } from "react-icons/pi";
import { useAddPaymentMutation } from "../../Context/api/payment";
import { useGetPaymentsByStudentIdQuery as useGetPyByStId } from "../../Context/api/payment";
import { enqueueSnackbar as EnSn } from "notistack";

export const StudentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // Get student by id
  const { data = null, isLoading } = useGetStudentByIdQuery(id);
  const student = data?.innerData || null;
  const groups = student?.groups || null;

  // Add payment
  const [addPayment] = useAddPaymentMutation();
  const { data: payments = null } = useGetPyByStId(id);
  const paymentHistory = payments?.innerData || null;

  const handlePayment = async (e) => {
    e.preventDefault();
    const values = Object.fromEntries(new FormData(e.target));
    values.amount = values.amount.replace(/\D/g, "");
    values.student_id = id;

    if (!window.confirm("To'lovni tasdiqlaysizmi?")) return;
    const { data, error } = await addPayment(values);
    let message, variant;

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "To'lov muvaffaqiyatli amalga oshirildi";
    variant = data?.variant || "success";
    return EnSn(message, { variant });
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Ma'lumotlar</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate(`/student/update/${student?.id}`)}>
            <MdModeEditOutline />
          </button>
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container f6">
        {/* Student detail information */}
        <Detail>
          <h3>O'quvchi ma'lumotlari</h3>
          <p>
            <span>Ism Familiya</span>
            <b>{student?.fullname || <Skeleton width={200} />}</b>
          </p>

          <p>
            <span>Telefon raqam</span>
            <b>
              {student?.student_phone ? (
                <PatternFormat
                  format="+### ## ### ####"
                  allowEmptyFormatting
                  mask=" "
                  value={student?.student_phone}
                  displayType="text"
                />
              ) : (
                <Skeleton width={200} />
              )}
            </b>
          </p>

          <p>
            <span>Ota-ona telefon raqami</span>
            <b>
              {student?.parents_phone ? (
                <PatternFormat
                  format="+### ## ### ####"
                  allowEmptyFormatting
                  mask=" "
                  value={student?.parents_phone}
                  displayType="text"
                />
              ) : (
                <Skeleton width={200} />
              )}
            </b>
          </p>

          <p>
            <span>Tug'ilgan kun</span>
            <b>{student?.birthday || <Skeleton width={130} />}</b>
          </p>

          <p>
            <span>Xisobidagi pul</span>
            <b>
              {student?.balance >= 0 ? (
                <NumericFormat
                  allowNegative={false}
                  allowLeadingZeros={false}
                  decimalScale={0}
                  fixedDecimalScale={false}
                  value={student?.balance}
                  displayType="text"
                  thousandSeparator=" "
                  suffix=" so'm"
                  style={{ textTransform: "lowercase" }}
                />
              ) : (
                <Skeleton width={100} />
              )}
            </b>
          </p>

          <p>
            <span>Bizni qayerdan topdingiz</span>
            <b>{student?.source || <Skeleton width={200} />}</b>
          </p>

          <p>
            <span>Ro'yxatdan o'tgan sana</span>
            <b>
              {dateFormat(student?.joined_at, "datetime", "ru-RU") || (
                <Skeleton width={200} />
              )}
            </b>
          </p>

          <p>
            <span>Oxirgi yangilanish</span>
            <b>
              {dateFormat(student?.updated_at, "datetime", "ru-RU") || (
                <Skeleton width={200} />
              )}
            </b>
          </p>

          <p>
            <span>Manzil</span>
            <b>{student?.address || <Skeleton width={220} />}</b>
          </p>
        </Detail>

        {/* Student group information */}
        <div className="page-container__box">
          <h3>O'quvchining guruhlari</h3>
          <Table status={!isLoading}>
            <thead>
              <tr>
                <th>№</th>
                <th>Gurux nomi</th>
                <th>O'qituvchi</th>
                <th>Gurux haqida</th>
                <th>O'quvchilar</th>
              </tr>
            </thead>
            <tbody>
              {groups?.map((group, index) => (
                <tr
                  key={group.id}
                  onClick={() => navigate(`/group/${group.id}`)}
                >
                  <td>{index + 1}</td>
                  <td>{group?.group_name}</td>
                  <td>{group?.teacher}</td>
                  <td>{group?.description}</td>
                  <td>
                    <NumericFormat
                      value={group?.students}
                      displayType="text"
                      thousandSeparator=" "
                      suffix=" ta"
                      style={{ textTransform: "lowercase" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Link to={`/group?add/student/to/group/student_id=${id}`}>
            + Gruxga qo'shish
          </Link>
        </div>

        {/* Payment form */}
        <div className="page-container__box">
          <h3>To'lovni qabul qilish</h3>
          <form className="payment-form" onSubmit={handlePayment}>
            <label>
              <span>Gurux nomi</span>
              <select name="group_id">
                {groups?.map((group) => {
                  return (
                    <option key={group.id} value={group.id}>
                      {group.group_name}
                    </option>
                  );
                })}
              </select>
            </label>

            <label>
              <span>To'lov turi</span>
              <select name="payment_type">
                <option value="Naqd">Naqd</option>
                <option value="Plastik karta">Plastik karta</option>
                <option value="Pul o'tkazma">Pul o'tkazma</option>
              </select>
            </label>

            <label>
              <span>To'lov summasi</span>
              <NumericFormat
                thousandSeparator=" "
                suffix=" so'm"
                name="amount"
                placeholder="250 000 so'm"
                required
                autoComplete="off"
              />
            </label>

            <label>
              <span>To'lov sanasi</span>
              <input
                type="date"
                name="date"
                placeholder="To'lov sanasi"
                required
                autoComplete="off"
                defaultValue={dateFormat(new Date(), "date")}
              />
            </label>
            <label>
              <span>To'lovni tasdiqlash</span>
              <button>
                <PiContactlessPaymentLight />
                <span>Tasdiqlash</span>
              </button>
            </label>
          </form>
        </div>

        {/* Payment history */}
        <div className="page-container__box">
          <h3>
            {paymentHistory?.length
              ? "To'lovlar tarixi"
              : "To'lovlar mavjud emas"}
          </h3>
          <Table status={!isLoading && paymentHistory}>
            <thead>
              <tr>
                <th>№</th>
                <th>Gurux nomi</th>
                <th>To'lov turi</th>
                <th>To'lov summasi</th>
                <th>To'lov sanasi</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory?.map((payment, index) => {
                return (
                  <tr
                    key={payment?.id}
                    onClick={() => navigate(`/payment/${payment?.id}`)}
                  >
                    <td>{index + 1}</td>
                    <td>{payment?.group_name}</td>
                    <td>{payment?.payment_type}</td>
                    <td>
                      <NumericFormat
                        thousandSeparator=" "
                        suffix=" so'm"
                        value={payment?.amount}
                        displayType="text"
                        style={{ textTransform: "lowercase" }}
                      />
                    </td>
                    <td>{dateFormat(payment?.date, "date", "ru-RU")}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
