import React from "react";
import "./Contract.css";

export const Contract = () => {
  return (
    <div>
      <h1>Contract</h1>
    </div>
  );
};
