import { apiSlice } from "./api";

export const contractApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // add contract: POST "/add/contract"
    addContract: builder.mutation({
      query: (data) => ({
        url: "/add/contract",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["updateContract", "updateStudent", "updateGroup"],
    }),
  }),
});

export const { useAddContractMutation } = contractApi;
