import React, { useState } from "react";
import "./TimeTable.css";
import { Header } from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { useGetTimeTableQuery } from "../../Context/api/group";
import { week } from "../../Utility/week";
import { dateFormat } from "../../Utility/dateFormat";
import Skeleton from "react-loading-skeleton";

export const TimeTable = () => {
  const [time, setTime] = useState(new Date().getTime());
  const [getAll, setGetAll] = useState(false);
  const defaultDate = new Date().toLocaleDateString("sv-SE");
  const [date, setDate] = useState(defaultDate);

  const navigate = useNavigate();
  const { data = null, isLoading } = useGetTimeTableQuery(time);
  const timeTable = data?.innerData || [];
  const go = (e) => navigate(e);

  const handleTime = (e) => {
    setDate(e.target.value);
    let date, time, value;

    value = e.target.value;
    date = value + " " + new Date().toLocaleTimeString("sv-SE");

    console.log(date);

    let dateTime = new Date(date);
    time = dateTime.getTime();
    return isNaN(time) ? null : setTime(time);
  };

  const handleGetAll = () => {
    setGetAll(!getAll);
    setTime(getAll ? new Date().getTime() : new Date().getTime() + "?all=true");
    const date = new Date().toLocaleDateString("sv-SE");
    return getAll ? setDate(date) : null;
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Darslar jadvali</h2>
        <div className="header-container__actions">
          <label
            className={getAll ? "get-all-groups active" : "get-all-groups"}
          >
            <input type="checkbox" onChange={handleGetAll} />
            <span>Bugungi barcha guruhlarni ko'rsatish</span>
          </label>
          <input
            style={{ display: getAll ? "none" : "" }}
            type="date"
            onChange={handleTime}
            value={date}
          />
        </div>
      </Header>

      <div className="page-container f6 page-container__table">
        {timeTable?.map((item, index) => {
          return (
            <div
              className="table-box"
              key={index}
              onClick={() => go(`/group/${item?.id}`)}
            >
              <h3>
                {item?.group_name || "Loading..."}
                <span className="live"></span>
              </h3>
              <div className="table-box__info">
                <p>
                  <span>O'qituvchi: </span>
                  <span style={{ color: item?.teacher ? "" : "red" }}>
                    {item?.teacher || "O'qituvchi yo'q"}
                  </span>
                </p>
                <p>
                  <span>Dars vaqti: </span>
                  {item?.time || <Skeleton width={100} />}
                </p>
                <p>
                  <span>Dars kunlari: </span>{" "}
                  {week(item?.days) || <Skeleton width={100} />}
                </p>
                <p>
                  <span>O'quvchilar soni: {item?.students || 0} ta</span>
                </p>
                <p>
                  <span>Active: </span>
                  {dateFormat(item?.started_at, "date", "ru-RU")}
                  {" - "}
                  {dateFormat(item?.finished_at, "date", "ru-RU")}
                </p>
              </div>
            </div>
          );
        })}

        <h1 style={{ display: timeTable.length ? "none" : "" }}>
          {isLoading ? "" : "Hozircha darslar mavjud emas"}
        </h1>
      </div>
    </div>
  );
};
