import { apiSlice } from "./api";

export const studentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // add a student endpoint: POST => 'add/student'
    addStudent: builder.mutation({
      query: (student) => ({
        url: "/add/student",
        method: "POST",
        body: student,
      }),
      invalidatesTags: ["updateStudent"],
    }),

    // get all students: GET "/get/student"
    getStudents: builder.query({
      query: () => "/get/student",
      providesTags: ["updateStudent", "updateTeacher"],
    }),

    // get a student by id: GET "/get/student/:id"
    getStudentById: builder.query({
      query: (id) => `/get/student/${id}`,
      providesTags: ["updateStudent", "updateTeacher"],
    }),

    // update a student by id: PATCH "/update/student/:id"
    updateStudent: builder.mutation({
      query: ({ id, student }) => ({
        url: `/update/student/${id}`,
        method: "PATCH",
        body: student,
      }),
      invalidatesTags: ["updateStudent", "updateGroup"],
    }),

    // add student to a group: PATCH "/add/student/to/group/:group_id/:student_id"
    addStudentToGroup: builder.mutation({
      query: ({ group_id, student_id }) => ({
        url: `/add/student/to/group/${group_id}/${student_id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["updateStudent", "updateGroup", "updateTeacher"],
    }),
  }),
});

export const {
  useAddStudentMutation,
  useGetStudentsQuery,
  useGetStudentByIdQuery,
  useUpdateStudentMutation,
  useAddStudentToGroupMutation,
} = studentApi;
