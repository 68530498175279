import React, { useState } from "react";
import { Header } from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { Table } from "../../Components/Table/Table";
import { useGetAllPaymentsQuery } from "../../Context/api/payment";
import { dateFormat } from "../../Utility/dateFormat";
import { NumericFormat } from "react-number-format";
import { betweenDate } from "../../Utility/betweenDate";

export const Payment = () => {
  const navigate = useNavigate();
  const [get, setGet] = useState(betweenDate());
  const query = `from=${get.firstDay}&to=${get.today}`;
  const { data: allPayments } = useGetAllPaymentsQuery(query);
  const payments = allPayments?.innerData || [];

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">To'lovlar ro'yxati</h2>
        <div className="header-container__actions">
          <input
            type="date"
            defaultValue={get.firstDay}
            format="yyyy-mm-dd"
            onChange={(e) =>
              setGet({
                ...get,
                firstDay: new Date(e.target.value).toLocaleDateString("sv-SE"),
              })
            }
          />
          <input
            type="date"
            defaultValue={get.today}
            format="yyyy-mm-dd"
            onChange={(e) =>
              setGet({
                ...get,
                today: new Date(e.target.value).toLocaleDateString("sv-SE"),
              })
            }
          />
        </div>
      </Header>

      <div className="page-container ff">
        <Table>
          <thead>
            <tr>
              <th>№</th>
              <th>Guruh nomi</th>
              <th>To'lov turi</th>
              <th>O'quvchi ismi</th>
              <th>To'lov summasi</th>
              <th>To'lov sanasi</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => {
              return (
                <tr
                  key={payment.id}
                  onClick={() => navigate(`/payment/${payment.id}`)}
                >
                  <td>{index + 1}</td>
                  <td>{payment.group_name}</td>
                  <td>{payment.payment_type}</td>
                  <td>{payment.student_name}</td>
                  <td>
                    <NumericFormat
                      value={payment.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" so'm"}
                      style={{ textTransform: "lowercase" }}
                    />
                  </td>
                  <td>{dateFormat(payment.date, "date", "ru-RU")}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

// amount: 320000;
// created_at: "2023-12-20T21:46:54.000Z";
// date: "2023-12-22T19:00:00.000Z";
// group_id: "e79b07";
// group_name: "IELTS";
// id: "cc21b5";
// learning_center_id: "bc5525";
// payment_type: "Plastik karta";
// student_id: "b6eb8d";
// updated_at: "2023-12-20T21:46:54.000Z";
