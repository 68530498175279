export const dateFormat = (date, type, format = "sv-SE") => {
  if (!date) return "";

  switch (type) {
    case "data":
      return new Date(date).toLocaleDateString(format);

    case "time":
      return new Date(date).toLocaleTimeString(format);

    case "datetime":
      return new Date(date).toLocaleString(format);

    default:
      return new Date(date).toLocaleDateString(format);
  }
};
