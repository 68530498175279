import React, { memo } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import "./Layout.css";

import { MdSpaceDashboard, MdOutlinePayment } from "react-icons/md";
import { PiStudent, PiGitBranch } from "react-icons/pi";
import { VscGroupByRefType } from "react-icons/vsc";
import { LiaUserTieSolid } from "react-icons/lia";
import { MdOutlineSupportAgent, MdOutlineAccountCircle } from "react-icons/md";
import { HiMiniCalendarDays } from "react-icons/hi2";
import { LuClipboardList } from "react-icons/lu";
import { PiSealWarningFill } from "react-icons/pi";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { PiCertificate } from "react-icons/pi";

export const Layout = memo(() => {
  return (
    <main className="main">
      <aside className="sidebar">
        <div className="sidebar-header">
          <a
            href="https://controledu.uz"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-header__link"
          >
            Control Edu
          </a>
        </div>
        <nav className="sidebar-nav">
          {menu?.map((item, index) => {
            return (
              <NavLink to={item.link} className="sidebar-nav__link" key={index}>
                {item.icon} <span>{item.title}</span>
              </NavLink>
            );
          })}
        </nav>
      </aside>
      <header className="header">
        <p className="header__title">
          <span className="header__title__text">O'quv markaz nomi</span>
          <span
            className="header__title__icon"
            style={{ color: true ? "2ea254" : "red" }}
          >
            {true ? <RiVerifiedBadgeFill /> : <PiSealWarningFill />}
          </span>
        </p>

        <div className="header__links">
          <a
            href="https://t.me/controledubot"
            target="_blank"
            rel="noopener noreferrer"
            className="header__link"
            title="Support"
          >
            <MdOutlineSupportAgent />
          </a>

          <Link to="/profile" className="header__link" title="Profile">
            <span
              className={true ? "header__link__notification active" : ""}
            ></span>
            <MdOutlineAccountCircle />
          </Link>
        </div>
      </header>
      <section className="content">
        <Outlet />
      </section>
    </main>
  );
});

const menu = [
  {
    title: "Dashboard",
    icon: <MdSpaceDashboard />,
    link: "/dashboard",
  },
  {
    title: "Ustozlar",
    icon: <LiaUserTieSolid />,
    link: "/teacher",
  },
  {
    title: "Gruxlar",
    icon: <VscGroupByRefType />,
    link: "/group",
  },
  {
    title: "Dars jadvali",
    icon: <HiMiniCalendarDays />,
    link: "/time-table",
  },
  {
    title: "O'quvchilar",
    icon: <PiStudent />,
    link: "/student",
  },
  {
    title: "To'lovlar",
    icon: <MdOutlinePayment />,
    link: "/payment",
  },
  {
    title: "Filiallar",
    icon: <PiGitBranch />,
    link: "/branches",
  },
  {
    title: "Hisobotlar",
    icon: <LuClipboardList />,
    link: "/report",
  },
  {
    title: "Billing",
    icon: <PiCertificate />,
    link: "/billing",
  },
];
