import React from "react";
import "./Profile.css";
import logo from "../../Assets/logo.svg";
import { enqueueSnackbar as EnSn } from "notistack";

export const Profile = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    EnSn("ok", { variant: "success" });
  };
  const cl = null;

  return (
    <div className="profile">
      <form className="profile-form" onSubmit={handleSubmit}>
        <h3 className="profile-form__title">Profil ma'lumotlari</h3>
        <label className="profile-label">
          <span>Boshqaruvchining Ism Familiyasi</span>
          <input
            style={{ borderColor: cl?.fullname ? "#ccc" : "red" }}
            type="text"
            name="fullname"
            defaultValue={cl?.fullname ? cl?.fullname : ""}
            autoComplete="off"
            placeholder="Ism Familiya"
          />
        </label>

        <label className="profile-label">
          <span>O'quv markaz nomi</span>
          <input
            style={{ borderColor: cl?.learning_center ? "#ccc" : "red" }}
            type="text"
            name="learning_center"
            defaultValue={cl?.learning_center ? cl?.learning_center : ""}
            autoComplete="off"
            placeholder="Web Developer Academy"
          />
        </label>
        <label className="profile-label">
          <span>O'quv markaz manzili</span>
          <input
            style={{ borderColor: cl?.address ? "#ccc" : "red" }}
            type="text"
            name="address"
            defaultValue={cl?.address ? cl?.address : ""}
            autoComplete="off"
            placeholder="Namangan shahar, Islom Karimov ko'chasi"
          />
        </label>

        <label className="profile-label">
          <span>Ro'yhatdan o'tgan sana</span>
          <p className="profile-label__text">
            {new Date(cl?.joined_at).toLocaleString("ru-RU")}
          </p>
        </label>

        <label className="profile-label">
          <span>Oxirgi yangilanish</span>
          <p className="profile-label__text">
            {new Date(cl?.updated_at).toLocaleString("ru-RU")}
          </p>
        </label>

        <button className="profile-form__submit" type="submit">
          Yangilash
        </button>
      </form>

      <figure className="profile-logo">
        <img src={logo} alt="logo" />
        <figcaption className="profile-logo__caption">
          <h3>Control Edu Ma’lumotlar Xavfsizligi Siyosati</h3>
          <p>
            Sizning shaxsiy ma’lumotlaringizning xavfsizligi va maxfiyligi biz
            uchun juda muhim. Biz hech qachon sizning shaxsiy ma’lumotlaringizni
            uchinchi tomonlarga taqdim etmaymiz va ularni har qanday holatda ham
            himoya qilamiz. Sizning ma’lumotlaringiz faqat sizning ruxsatingiz
            bilan va kerakli holatlarda ishlatilishi mumkin.
          </p>

          <p>
            <b>Maxfiylik:</b> Biz sizning shaxsiy ma’lumotlaringizni qat’iy
            maxfiylik bilan saqlaymiz.
          </p>
          <p>
            <b>Ruxsat:</b> Sizning ma’lumotlaringiz faqat sizning aniq
            ruxsatingiz bilan ishlatiladi.
          </p>

          <p>
            <b>Xavfsizlik:</b> Biz zamonaviy xavfsizlik texnologiyalaridan
            foydalanamiz sizning ma’lumotlaringizni himoya qilish uchun.
          </p>

          <p>
            <b>Cheklov:</b> Sizning ma’lumotlaringiz faqat zarur bo’lgan
            holatlarda va minimal miqdorda ishlatiladi.
          </p>

          <p>
            <b>Shaffoflik:</b> Siz har doim o’z ma’lumotlaringizni ko’rish va
            boshqarish huquqiga egasiz.
          </p>

          <button
            className="profile__logout"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Tizimdan chiqish
          </button>

          <a
            href="https://t.me/controledubot"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
        </figcaption>
      </figure>
    </div>
  );
};
