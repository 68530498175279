import React, { useState } from "react";
import { Header } from "../../Components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { FormBox } from "../../Components/Form/Form";
import { FaRegIdCard } from "react-icons/fa";
import { useGetTeacherQuery } from "../../Context/api/teacher";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useUpdateTeacherMutation } from "../../Context/api/teacher";
import { useDispatch } from "react-redux";
import { enqueueSnackbar as EnSn } from "notistack";
import { GoTrash } from "react-icons/go";
import { useDeleteTeacherMutation } from "../../Context/api/teacher";
import { MdOutlineArrowBackIos } from "react-icons/md";

export const UpdateTeacher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data = null } = useGetTeacherQuery(id);
  const teacher = data?.innerData || null;
  const condition = teacher?.salary_type || "Kelishuv";
  const [salaryType, setSalaryType] = useState(condition);

  const [updateTeacher] = useUpdateTeacherMutation();
  const [deleteTeacher] = useDeleteTeacherMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    const confirm = window.confirm("Ma'lumotlarni taxrirlashni xohlaysizmi?");
    if (!confirm) return;
    dispatch({ type: "LOADING", payload: true });
    const value = Object.fromEntries(new FormData(e.target));
    value.phone = value.phone.replace(/[\s+\-()]/g, "");
    value.salary = value.salary.replace(/\D/g, "");
    const { data, error } = await updateTeacher({ id, teacher: value });
    dispatch({ type: "LOADING", payload: false });
    let message, variant;

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "Ma'lumotlar muvaffaqiyatli taxrirlandi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    return navigate(-1);
  };

  const onDelete = async () => {
    const confirm = window.confirm("O'qituvchini o'chirishni xohlaysizmi?");
    if (!confirm) return;
    dispatch({ type: "LOADING", payload: true });
    const { data, error } = await deleteTeacher(id);
    dispatch({ type: "LOADING", payload: false });
    let message, variant;

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "Ma'lumotlar muvaffaqiyatli o'chirildi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    return navigate("/teacher");
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Ma'lumotlarni taxrirlash</h2>
        <div className="header-container__actions">
          <button onClick={onDelete}>
            <GoTrash />
          </button>
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container ff">
        <FormBox>
          <form onSubmit={onSubmit}>
            <label>
              <span>Ism Familiya*</span>
              <input
                type="text"
                name="fullname"
                placeholder="Ism Familiya"
                required
                autoComplete="off"
                defaultValue={teacher?.fullname}
              />
            </label>
            <label>
              <span>Telefon raqam*</span>
              <PatternFormat
                format="+998 ## ### ####"
                allowEmptyFormatting
                mask=" "
                name="phone"
                placeholder="Telefon raqam"
                required
                autoComplete="off"
                value={String(teacher?.phone).slice(3, 12)}
              />
            </label>
            <label>
              <span>Fan nomi*</span>
              <input
                type="text"
                name="subject"
                placeholder="Fan nomi"
                required
                autoComplete="off"
                defaultValue={teacher?.subject}
              />
            </label>

            <label>
              <span>Ish haqi turini tanlang*</span>
              <select
                name="salary_type"
                onChange={(e) => setSalaryType(e.target.value)}
                value={salaryType}
              >
                <option value="Kelishuv">
                  Kelishuv asosida (o'zgaruvchan summa evaziga)
                </option>
                <option value="Ulush">
                  Ulush asosida (malum foiz evaziga)
                </option>
                <option value="Oylik">Oylik (aniq summa evaziga)</option>
              </select>
            </label>

            <label
              style={{
                display: salaryType === "Ulush" ? "flex" : "none",
              }}
            >
              <span>Ulushingizni kiriting*</span>
              <NumericFormat
                allowLeadingZeros
                mask=" "
                name="salary"
                placeholder="Ulushingizni kiriting"
                thousandSeparator=" "
                suffix=" %"
                autoComplete="off"
                value={salaryType ? null : ""}
              />
            </label>

            <label
              style={{
                display: salaryType === "Oylik" ? "flex" : "none",
              }}
            >
              <span>Oylik ish haqi*</span>
              <NumericFormat
                allowLeadingZeros
                mask=" "
                name="salary"
                placeholder="Oylik ish haqi"
                thousandSeparator=" "
                autoComplete="off"
                suffix=" so'm"
                value={salaryType ? null : ""}
              />
            </label>

            <label>
              <button type="submit">
                <FaRegIdCard />
                Taxrirlash
              </button>
            </label>
          </form>
        </FormBox>
      </div>
    </div>
  );
};
