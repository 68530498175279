import React from "react";
import { Header } from "../../Components/Header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { NumericFormat, PatternFormat } from "react-number-format";
import { TbLicense } from "react-icons/tb";
import { useGetGroupQuery } from "../../Context/api/group";
import { week } from "../../Utility/week";
import { enqueueSnackbar as EnSn } from "notistack";
import { useAddContractMutation } from "../../Context/api/contract";

export const AddContract = () => {
  const navigate = useNavigate();
  const { group_id, student_id } = useParams();
  const { data = null } = useGetGroupQuery(group_id);
  const group = data?.innerData || null;
  const student = group?.students?.find((s) => s.id === student_id) || null;
  const [addContract] = useAddContractMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = Object.fromEntries(new FormData(e.target));
    values.payment = values.payment.replace(/\D/g, "");
    values.student_id = student_id;
    values.group_id = group_id;
    let message, variant, confirm;
    confirm = window.confirm("Shartnoma tuzilsinmi?");
    if (!confirm) return;
    const { data, error } = await addContract(values);
    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }
    message = data?.message || "Shartnoma muvaffaqiyatli tuzildi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    navigate(-1);
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Shartnoma tuzish</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container f6">
        <form className="contract-form" onSubmit={handleSubmit}>
          <p>
            <span>O'quvchi Ism Familiyasi</span>
            <b>
              <Link to={`/student/${student?.id}`}>{student?.fullname}</Link>
            </b>
          </p>

          <p>
            <span>Telefon raqami</span>
            <b>
              <PatternFormat
                format="+### ## ### ####"
                allowEmptyFormatting
                mask=" "
                value={student?.student_phone}
                displayType="text"
              />
            </b>
          </p>

          <p>
            <span>Guruh nomi</span>
            <b>{group?.group_name}</b>
          </p>

          <p>
            <span>Dars vaqti</span>
            <b>{group?.time}</b>
          </p>

          <p>
            <span>Dars kunlari</span>
            <b>{week(group?.days)}</b>
          </p>

          <p>
            <span>O'qituvchi</span>
            <b>
              <Link to={`/teacher/${group?.teacher_id}`}>{group?.teacher}</Link>
            </b>
          </p>

          <label>
            <span>Shartnoma boshlanish sanasi</span>
            <input
              type="date"
              defaultValue={new Date().toLocaleDateString("sv-SE")}
              name="started_at"
              required
            />
          </label>

          <label>
            <span>Shartnoma tugash sanasi</span>
            <input
              type="date"
              defaultValue={new Date(+new Date() + 15825000000)
                .toLocaleDateString("sv-SE")
                .replaceAll(".", "-")}
              name="ended_at"
              required
            />
          </label>

          <label>
            <span>Oylik to'lov miqdori</span>
            <NumericFormat
              thousandSeparator=" "
              suffix=" so'm"
              name="payment"
              placeholder="Oylik maosh"
              required
              autoComplete="off"
              defaultValue={300000}
              style={{ textTransform: "lowercase" }}
            />
          </label>
          <label>
            <button>
              <TbLicense />
              <span>Shartnoma tuzish</span>
            </button>
          </label>
        </form>
      </div>
    </div>
  );
};

// Group:
// created_at: "2023-12-15T07:42:02.000Z";
// days: "[2,4,6]";
// description: "express js da telegram bot";
// finished_at: "2024-03-31T22:32:16.000Z";
// group_name: "Telegram Bot";
// id: "90a2b6";
// learning_center_id: "b25ba4";
// room: "17-xona";
// started_at: "2023-12-31T22:32:16.000Z";
// students: teacher: "Oybek abdujabborov";
// teacher_id: "d28ebd";
// teachers: time: "08:00 - 10:00";
// updated_at: "2023-12-15T10:32:16.000Z";

// Student:
// address: "Namnagan, To'raqorg'on";
// birthday: "1996-03-04";
// budget: 0;
// contract: null;
// fullname: "Oybek Abdujabborov";
// groups: '["ac0b30","90a2b6","33bca0","e6b716","ea23ba","41fb2e"]';
// id: "213041";
// joined_at: "2023-12-15T20:53:33.000Z";
// learning_center_id: "b25ba4";
// parents_phone: 998950270496;
// source: "Do'stim orqali";
// student: "he";
// student_phone: 998950270496;
// teacher: null;
// updated_at: "2023-12-16T16:09:31.000Z";
