import React from "react";
import ReactDOM from "react-dom/client";
import "./Assets/Global.css";
import "react-loading-skeleton/dist/skeleton.css";
import "animate.css";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import { store } from "./Context/store";
import { Provider } from "react-redux";
import { Loading } from "./Components/Loading/Loading";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <Loading />
        <Router />
      </SnackbarProvider>
    </Provider>
  </BrowserRouter>
);
