import { apiSlice } from "./api";

export const groupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // add a group endpoint: POST => 'add/group'
    addGroup: builder.mutation({
      query: (group) => ({
        url: "/add/group",
        method: "POST",
        body: group,
      }),
      invalidatesTags: ["updateGroup"],
    }),

    // get all groups endpoint: GET => 'get/group'
    getAllGroups: builder.query({
      query: () => "/get/group",
      providesTags: ["updateGroup"],
    }),

    // get a group endpoint: GET => 'get/group/:id'
    getGroup: builder.query({
      query: (id) => `/get/group/${id}`,
      providesTags: ["updateGroup"],
    }),

    // get groups by teacher id endpoint: GET => '/get/group/teacher/:id'
    getGroupsByTeacherId: builder.query({
      query: (id) => `/get/group/teacher/${id}`,
      providesTags: ["updateGroup"],
    }),

    // update a group endpoint: PUT => 'update/group/:id'
    updateGroup: builder.mutation({
      query: ({ id, group }) => ({
        url: `/update/group/${id}`,
        method: "PATCH",
        body: group,
      }),
      invalidatesTags: ["updateGroup", "updateTeacher", "updateStudent"],
    }),

    // delete a group endpoint: DELETE => 'delete/group/:id'
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `/delete/group/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["updateGroup"],
    }),

    // add teacher to a group endpoint: POST => '/add/teacher/group/:group_id/:teacher_id'
    addTeacherToGroup: builder.mutation({
      query: ({ group_id, teacher_id }) => ({
        url: `/add/teacher/group/${group_id}/${teacher_id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["updateGroup", "updateTeacher"],
    }),

    // get time table: GET "/get/time/table/:time"
    getTimeTable: builder.query({
      query: (time) => ({
        url: `/get/time/table/${time}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddGroupMutation,
  useGetAllGroupsQuery,
  useGetGroupQuery,
  useGetGroupsByTeacherIdQuery,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useAddTeacherToGroupMutation,
  useGetTimeTableQuery,
} = groupApi;
