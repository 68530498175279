import React, { memo } from "react";
import "./Table.css";

export const Table = memo(({ children, status = true }) => {
  return (
    <div
      className="table-container"
      style={{ display: status ? "flex" : "none" }}
    >
      <table className="table" border="1">
        {children}
      </table>
    </div>
  );
});
