import React, { memo } from "react";
import "./Form.css";

export const FormBox = memo(({ children }) => {
  return (
    <div className="form-box">
      {children}

      <div className="form-box__policy">
        <h1>Control Edu Ma’lumotlar Xavfsizligi Siyosati</h1>
        <p>
          Sizning shaxsiy ma’lumotlaringizning xavfsizligi va maxfiyligi biz
          uchun juda muhim. Biz hech qachon sizning shaxsiy ma’lumotlaringizni
          uchinchi tomonlarga taqdim etmaymiz va ularni har qanday holatda ham
          himoya qilamiz. Sizning ma’lumotlaringiz faqat sizning ruxsatingiz
          bilan va kerakli holatlarda ishlatilishi mumkin.
        </p>

        <p>
          <b>Maxfiylik:</b> Biz sizning shaxsiy ma’lumotlaringizni qat’iy
          maxfiylik bilan saqlaymiz.
        </p>
        <p>
          <b>Ruxsat:</b> Sizning ma’lumotlaringiz faqat sizning aniq ruxsatingiz
          bilan ishlatiladi.
        </p>

        <p>
          <b>Xavfsizlik:</b> Biz zamonaviy xavfsizlik texnologiyalaridan
          foydalanamiz sizning ma’lumotlaringizni himoya qilish uchun.
        </p>

        <p>
          <b>Cheklov:</b> Sizning ma’lumotlaringiz faqat zarur bo’lgan
          holatlarda va minimal miqdorda ishlatiladi.
        </p>

        <p>
          <b>Shaffoflik:</b> Siz har doim o’z ma’lumotlaringizni ko’rish va
          boshqarish huquqiga egasiz.
        </p>
      </div>
    </div>
  );
});
