import React from "react";
import { Header } from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { FormBox } from "../../Components/Form/Form";
import { PatternFormat } from "react-number-format";
import { useAddStudentMutation } from "../../Context/api/student";
import { enqueueSnackbar as EnSn } from "notistack";

export const AddStudent = () => {
  const navigate = useNavigate();
  const [addStudent] = useAddStudentMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = Object.fromEntries(new FormData(e.target));
    values.parents_phone = values.parents_phone.replace(/[\s+\-()]/g, "");
    values.student_phone = values.student_phone.replace(/[\s+\-()]/g, "");
    const { data, error } = await addStudent(values);

    let message, variant;
    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }
    message = data?.message || "O'quvchi bazaga muvaffaqiyatli qo'shildi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    navigate(-1);
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">O'quvchi qo'shish</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container ff">
        <FormBox>
          <form onSubmit={handleSubmit}>
            <label>
              <span>Ism Familiya</span>
              <input
                type="text"
                name="fullname"
                autoFocus
                autoComplete="off"
                autoCapitalize="on"
                required
                placeholder="Ism Familiya"
                defaultValue={"Oybek Abdujabborov"}
              />
            </label>

            <label>
              <span>Ota-Onasining telefon raqami</span>
              <PatternFormat
                format="+998 ## ### ####"
                allowEmptyFormatting
                mask=" "
                name="parents_phone"
                autoComplete="off"
                required
                defaultValue={"+998 99 999 9999"}
              />
            </label>

            <label>
              <span>Shaxsiy telefon raqami</span>
              <PatternFormat
                format="+998 ## ### ####"
                allowEmptyFormatting
                mask=" "
                name="student_phone"
                autoComplete="off"
                required
                defaultValue={"+998 99 999 9999"}
              />
            </label>

            <div className="form-group">
              <label>
                <span>Tug'ilgan sanasi</span>
                <input
                  type="date"
                  name="birthday"
                  autoComplete="off"
                  required
                  defaultValue={new Date().toLocaleDateString("sv-SE")}
                />
              </label>
              <label>
                <span>O'quvchi</span>
                <select name="student" required>
                  <option value="he">O'g'il bola</option>
                  <option value="she">Qiz bola</option>
                </select>
              </label>
            </div>

            <label>
              <span>Yashash manzili</span>
              <input
                type="text"
                name="address"
                autoComplete="off"
                required
                placeholder="Yashash manzili"
                defaultValue={"Toshkent shahar, Yashnobod tumani"}
              />
            </label>

            <label>
              <span>Biz haqimizda qayerdan bildingiz?</span>
              <select name="source" required>
                <option value="website">Web syatimiz orqali</option>
                <option value="friend">Do'stim orqali</option>
                <option value="google">Google dan</option>
                <option value="instagram">Instagramdan</option>
                <option value="telegram">Telegramdan</option>
                <option value="facebook">Facebookdan</option>
                <option value="youtube">Youtube dan</option>
                <option value="other">Boshqa manbalar orqali</option>
              </select>
            </label>
            <label>
              <button>Ro'yxatga olish</button>
            </label>
          </form>
        </FormBox>
      </div>
    </div>
  );
};
