import React, { useState, useEffect } from "react";
import { FormBox } from "../../Components/Form/Form";
import { useGetGroupQuery } from "../../Context/api/group";
import { useParams } from "react-router-dom";
import { TbSpacingHorizontal } from "react-icons/tb";
import { Header } from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { useUpdateGroupMutation } from "../../Context/api/group";
import { enqueueSnackbar as EnSn } from "notistack";
import { dateFormat } from "../../Utility/dateFormat";
import { GoTrash } from "react-icons/go";
import { useDeleteGroupMutation } from "../../Context/api/group";
import { MdOutlineArrowBackIos } from "react-icons/md";

export const UpdateGroup = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data = null } = useGetGroupQuery(id);
  const group = data?.innerData || null;
  const started_time = group?.time?.split(" - ")[0];
  const finished_time = group?.time?.split(" - ")[1];
  const [days, setDays] = useState([...JSON.parse(group?.days || "[]")]);
  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();

  useEffect(() => {
    setDays([...JSON.parse(group?.days || "[]")]);
  }, [group]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirm = window.confirm("Ma'lumotlarni yangilashni xohlaysizmi?");
    if (!confirm) return;
    const values = Object.fromEntries(new FormData(e.target));
    const group = { ...values, days };
    group.time = `${group.started_time} - ${group.finished_time}`;
    delete group.started_time;
    delete group.finished_time;
    const { data, error } = await updateGroup({ id, group });
    let message, variant;
    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "Ma'lumotlar muvaffaqiyatli taxrirlandi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    return navigate(-1);
  };

  function handleDays(e) {
    const value = Number(e.target.value);
    if (days.includes(value)) {
      setDays(days.filter((day) => day !== value));
    } else {
      setDays([...days, value]);
    }
  }

  const onDelete = async () => {
    const confirm = window.confirm("Guruhni o'chirishni xohlaysizmi?");
    if (!confirm) return;
    const { data, error } = await deleteGroup(id);
    let message, variant;
    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "Ma'lumotlar muvaffaqiyatli o'chirildi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    return navigate("/group");
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Guruhi taxrirlash</h2>
        <div className="header-container__actions">
          <button onClick={onDelete}>
            <GoTrash />
          </button>
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container ff">
        <FormBox>
          <form onSubmit={handleSubmit}>
            <label>
              <span>Guruh nomi</span>
              <input
                type="text"
                name="group_name"
                required
                autoComplete="off"
                autoFocus
                placeholder="Guruh nomi"
                defaultValue={group?.group_name}
              />
            </label>
            <label>
              <span>Guruh haqida</span>
              <textarea
                name="description"
                required
                autoComplete="off"
                placeholder="Guruh haqida qisqacha ma'lumot"
                defaultValue={group?.description}
              />
            </label>
            <label>
              <span>Dars vaqti</span>
              <div className="form-time-group">
                <input
                  type="time"
                  name="started_time"
                  required
                  autoComplete="off"
                  placeholder="Boshlanish vaqti"
                  defaultValue={started_time}
                />
                <TbSpacingHorizontal />
                <input
                  type="time"
                  name="finished_time"
                  required
                  autoComplete="off"
                  placeholder="Tugash vaqti"
                  defaultValue={finished_time}
                />
              </div>
            </label>
            <div className="form-group-days">
              <span>Dars kunlari</span>

              {daysData.map((day) => {
                return (
                  <label
                    className={days.includes(day.id) ? "day active" : "day"}
                    key={day.id}
                  >
                    <input
                      type="checkbox"
                      value={day.id}
                      onChange={handleDays}
                    />
                    <span>{day.name}</span>
                  </label>
                );
              })}
            </div>

            <label>
              <span>Dars o'tiladigan xona</span>
              <input
                type="text"
                name="room"
                required
                autoComplete="off"
                placeholder="Masalan: 1-qavat, 3-xona"
                defaultValue={group?.room}
              />
            </label>
            <label className="form-group-date">
              <span>O'quv kurs davomiyligi</span>
              <div className="form-time-group">
                <input
                  type="date"
                  name="started_at"
                  required
                  autoComplete="off"
                  placeholder="Boshlanish vaqti"
                  defaultValue={dateFormat(group?.started_at)}
                />

                <TbSpacingHorizontal />

                <input
                  type="date"
                  name="finished_at"
                  required
                  autoComplete="off"
                  placeholder="Tugash vaqti"
                  defaultValue={dateFormat(group?.finished_at)}
                />
              </div>
            </label>

            <label>
              <button type="submit">Ma'lumotlarni yangilash</button>
            </label>
          </form>
        </FormBox>
      </div>
    </div>
  );
};

const daysData = [
  { id: 1, name: "Du" },
  { id: 2, name: "Se" },
  { id: 3, name: "Cho" },
  { id: 4, name: "Pa" },
  { id: 5, name: "Ju" },
  { id: 6, name: "Sha" },
  { id: 7, name: "Ya" },
];

// group_name: "23e23";
// days: "[2,4]";
// description: "23r23r";
// room: "23r23r23";
// started_at: "2023-12-13T00:11:16.000Z";
// finished_at: "2024-06-13T00:11:16.000Z";
// time: "08:00 - 10:00";
