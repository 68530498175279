import React, { useState } from "react";
import { Header } from "../../Components/Header/Header";
import { FormBox } from "../../Components/Form/Form";
import { PatternFormat, NumericFormat } from "react-number-format";
import { FaRegIdCard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { enqueueSnackbar as EnSn } from "notistack";
import { useAddTeacherMutation } from "../../Context/api/teacher";
import { MdOutlineArrowBackIos } from "react-icons/md";

export const AddTeacher = () => {
  const [salaryType, setSalaryType] = useState("Kelishuv");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addTeacher] = useAddTeacherMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirm = window.confirm("Siz yangi o'qituvchi qo'shmoqchimisiz?");
    if (!confirm) return;
    const value = Object.fromEntries(new FormData(e.target));
    value.phone = value.phone.replace(/[\s+\-()]/g, "");
    value.salary = value.salary.replace(/\D/g, "");
    dispatch({ type: "LOADING", payload: true });
    const { data, error } = await addTeacher(value);
    dispatch({ type: "LOADING", payload: false });

    let message;
    let variant;

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "O'qituvchi muvaffaqiyatli ro'yxatga olindi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    return navigate("/teacher");
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Ro'yxatga olish</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container ff">
        <FormBox>
          <form onSubmit={handleSubmit}>
            <label>
              <span>Ism Familiya*</span>
              <input
                type="text"
                name="fullname"
                placeholder="Ism Familiya"
                required
                autoComplete="off"
              />
            </label>

            <label>
              <span>Telefon raqam*</span>
              <PatternFormat
                format="+998 ## ### ####"
                allowEmptyFormatting
                mask=" "
                name="phone"
                placeholder="Telefon raqam"
                required
                autoComplete="off"
              />
            </label>

            <label>
              <span>Yo'nalish nomi*</span>
              <input
                type="text"
                name="subject"
                placeholder="Web dasturlash"
                required
                autoComplete="off"
              />
            </label>

            <label>
              <span>Ish haqi turini tanlang*</span>
              <select
                name="salary_type"
                onChange={(e) => setSalaryType(e.target.value)}
                value={salaryType}
              >
                <option value="Kelishuv">
                  Kelishuv asosida (o'zgaruvchan summa evaziga)
                </option>
                <option value="Ulush">
                  Ulush asosida (malum foiz evaziga)
                </option>
                <option value="Oylik">Oylik (aniq summa evaziga)</option>
              </select>
            </label>

            <label
              style={{
                display: salaryType === "Ulush" ? "flex" : "none",
              }}
            >
              <span>Ulashingizni kiriting*</span>
              <NumericFormat
                allowLeadingZeros
                mask=" "
                name="salary"
                placeholder="Ish haqi"
                thousandSeparator=" "
                suffix=" %"
                autoComplete="off"
                value={salaryType ? null : ""}
              />
            </label>

            <label
              style={{
                display: salaryType === "Oylik" ? "flex" : "none",
              }}
            >
              <span>Oylik ish haqi*</span>
              <NumericFormat
                allowLeadingZeros
                mask=" "
                name="salary"
                placeholder="Ish haqi"
                thousandSeparator=" "
                autoComplete="off"
                suffix=" so'm"
                value={salaryType ? null : ""}
              />
            </label>

            <label>
              <button>
                <FaRegIdCard />
                <span>Ro'yxatga olish</span>
              </button>
            </label>
          </form>
        </FormBox>
      </div>
    </div>
  );
};
