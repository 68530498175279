import React from "react";
import { Header } from "../../Components/Header/Header";
import { useParams, useNavigate } from "react-router-dom";
import { useGetTeacherQuery } from "../../Context/api/teacher";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Table } from "../../Components/Table/Table";
import { dateFormat } from "../../Utility/dateFormat";
import { week } from "../../Utility/week";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdModeEditOutline } from "react-icons/md";
import { Detail } from "../../Components/Detail/Detail";
import Skeleton from "react-loading-skeleton";

export const TeacherDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data = null } = useGetTeacherQuery(id);
  const teacher = data?.innerData || null;
  const groups = teacher?.groups || null;
  const students = teacher?.students || null;

  const handleGetStudent = (id) => navigate(`/student/${id}`);
  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Ma'lumotlar</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate(`/teacher/update/${teacher?.id}`)}>
            <MdModeEditOutline />
          </button>

          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container f6">
        <Detail>
          <h3>Asosiy ma'lumotlar</h3>
          <p>
            <span>Ism Familiya</span>
            <b>{teacher?.fullname || <Skeleton width={200} />}</b>
          </p>
          <p>
            <span>Telefon raqam</span>
            <b>
              {teacher?.phone ? (
                <PatternFormat
                  format="+### ## ### ####"
                  allowEmptyFormatting
                  mask=" "
                  value={teacher?.phone}
                  displayType="text"
                />
              ) : (
                <Skeleton width={170} />
              )}
            </b>
          </p>
          <p>
            <span>Fan nomi</span>
            <b>{teacher?.subject || <Skeleton width={200} />}</b>
          </p>
          <p>
            <span>Maoshi</span>
            <b style={{ textTransform: "lowercase" }}>
              {teacher?.fullname ? (
                <NumericFormat
                  value={teacher?.fullname ? teacher?.salary : 0}
                  allowLeadingZeros
                  thousandSeparator=" "
                  displayType="text"
                  suffix=" so'm"
                />
              ) : (
                <Skeleton width={90} />
              )}
            </b>
          </p>
          <p>
            <span>O'quvchilar soni</span>
            <b style={{ textTransform: "lowercase" }}>
              {teacher?.fullname ? (
                <NumericFormat
                  value={students ? students.length : 0}
                  allowLeadingZeros
                  thousandSeparator=" "
                  displayType="text"
                  suffix=" ta"
                />
              ) : (
                <Skeleton width={90} />
              )}
            </b>
          </p>
          <p>
            <span>Guruhlar soni</span>
            <b style={{ textTransform: "lowercase" }}>
              {teacher?.phone ? (
                <NumericFormat
                  value={groups ? groups.length : 0}
                  allowLeadingZeros
                  thousandSeparator=" "
                  displayType="text"
                  suffix=" ta"
                />
              ) : (
                <Skeleton width={90} />
              )}
            </b>
          </p>
          <p>
            <span>Ro'yxatga olingan sana</span>
            <b>
              {teacher?.joined_at ? (
                new Date(teacher?.joined_at).toLocaleString("ru-RU")
              ) : (
                <Skeleton width={190} />
              )}
            </b>
          </p>
          <p>
            <span>Yangilangan sana</span>
            <b>
              {teacher?.updated_at ? (
                new Date(teacher?.updated_at).toLocaleString("ru-RU")
              ) : (
                <Skeleton width={190} />
              )}
            </b>
          </p>
        </Detail>

        <div className="page-container__box">
          <h3>{groups ? "Guruhlar" : "Guruhlar mavjud emas"}</h3>
          <Table status={groups ? true : false}>
            <thead>
              <tr>
                <th>№</th>
                <th>Guruh nomi</th>
                <th>O'quvchilar soni</th>
                <th>Dars vaqti</th>
                <th>Dars kunlari</th>
                <th>Dars xonasi</th>
                <th>O'quv dastur</th>
              </tr>
            </thead>

            <tbody>
              {groups?.map((group, index) => {
                return (
                  <tr
                    key={group?.id}
                    onClick={() => navigate(`/group/${group?.id}`)}
                  >
                    <td>{index + 1}</td>
                    <td>{group?.group_name}</td>
                    <td>{group?.students}</td>
                    <td>{group?.time}</td>
                    <td>{week(group?.days)}</td>
                    <td>{group?.room}</td>
                    <td>
                      {dateFormat(group?.started_at, "date", "ru-RU")}
                      {" - "}
                      {dateFormat(group?.finished_at, "date", "ru-RU")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div className="page-container__box">
          <h3>{students ? "O'quvchilar" : "O'quvchilar mavjud emas"}</h3>

          <Table status={students}>
            <thead>
              <tr>
                <th>№</th>
                <th>Ism Familiya</th>
                <th>Telefon</th>
                <th>Manzil</th>
                <th>Ro'yxatdan o'/v</th>
              </tr>
            </thead>

            <tbody>
              {teacher?.students?.map((student, index) => {
                return (
                  <tr
                    key={student?.id}
                    onClick={() => handleGetStudent(student?.id)}
                  >
                    <td>{index + 1}</td>
                    <td>{student?.fullname}</td>
                    <td>
                      <PatternFormat
                        format="+### ## ### ####"
                        allowEmptyFormatting
                        mask=" "
                        value={student?.student_phone}
                        displayType="text"
                      />
                    </td>
                    <td>{student?.address}</td>
                    <td>
                      {dateFormat(student?.joined_at, "datetime", "ru-RU")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

// {
//     "message": "Ustoz ma'lumotlari",
//     "variant": "success",
//     "innerData": {
//         "id": "85bbee",
//         "fullname": "bekzod",
//         "phone": 998950270422,
//         "subject": "Web dasturlash",
//         "salary": 1000000,
//         "learning_center_id": "b25ba4",
//         "students": 0,
//         "groups": 0,
//         "joined_at": "2023-12-10T15:14:54.000Z",
//         "updated_at": "2023-12-10T15:14:54.000Z"
//     }
// }

// {
//             "id": "41fb2e",
//             "group_name": "Express js",
//             "description": "beckend",
//             "teacher_id": "68a0ec",
//             "teacher": "Gavhar",
//             "learning_center_id": "b25ba4",
//             "time": "08:00 - 10:00",
//             "days": "[1,3,5]",
//             "room": "4-xona",
//             "students": 0,
//             "started_at": "2023-12-15T07:47:54.000Z",
//             "finished_at": "2024-06-15T07:47:54.000Z",
//             "created_at": "2023-12-15T07:47:55.000Z",
//             "updated_at": "2023-12-15T07:47:55.000Z"
//         },
