import React from "react";
import { Header } from "../../Components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { FormBox } from "../../Components/Form/Form";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { PatternFormat } from "react-number-format";
import { useGetStudentByIdQuery } from "../../Context/api/student";
import { useUpdateStudentMutation } from "../../Context/api/student";
import { enqueueSnackbar as EnSn } from "notistack";
import { GoTrash } from "react-icons/go";

export const UpdateStudent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data = null } = useGetStudentByIdQuery(id);
  const student = data?.innerData || null;
  const [updateStudent] = useUpdateStudentMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = Object.fromEntries(new FormData(e.target));
    values.parents_phone = values.parents_phone.replace(/[\s+\-()]/g, "");
    values.student_phone = values.student_phone.replace(/[\s+\-()]/g, "");
    const { data, error } = await updateStudent({ id, student: values });
    let message, variant;

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "Ma'lumotlar muvaffaqiyatli taxrirlandi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    return navigate(-1);
  };

  const onDelete = async () => {
    let message, variant;
    message = "Kechirasiz, bu funksiya hali ishlashga tayyor emas!";
    variant = "info";
    return EnSn(message, { variant });
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Ma'lumotlarni taxrirlash</h2>
        <div className="header-container__actions">
          <button onClick={onDelete}>
            <GoTrash />
          </button>
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container ff">
        <FormBox>
          <form onSubmit={handleSubmit}>
            <label>
              <span>Ism Familiya</span>
              <input
                type="text"
                name="fullname"
                autoFocus
                autoComplete="off"
                autoCapitalize="on"
                required
                placeholder="Ism Familiya"
                defaultValue={student?.fullname}
              />
            </label>

            <label>
              <span>Ota-Onasining telefon raqami</span>
              <PatternFormat
                format="+998 ## ### ####"
                allowEmptyFormatting
                mask=" "
                name="parents_phone"
                autoComplete="off"
                required
                value={String(student?.parents_phone).slice(3, 12)}
              />
            </label>

            <label>
              <span>Shaxsiy telefon raqami</span>
              <PatternFormat
                format="+998 ## ### ####"
                allowEmptyFormatting
                mask=" "
                name="student_phone"
                autoComplete="off"
                required
                value={String(student?.student_phone).slice(3, 12)}
              />
            </label>

            <div className="form-group">
              <label>
                <span>Tug'ilgan sanasi</span>
                <input
                  type="date"
                  name="birthday"
                  autoComplete="off"
                  required
                  defaultValue={
                    student?.birthday || new Date().toLocaleDateString("sv-SE")
                  }
                />
              </label>
              <label>
                <span>O'quvchi</span>
                <select
                  name="student"
                  required
                  defaultValue={student?.student || "he"}
                >
                  <option value="he">O'g'il bola</option>
                  <option value="she">Qiz bola</option>
                </select>
              </label>
            </div>

            <label>
              <span>Yashash manzili</span>
              <input
                type="text"
                name="address"
                autoComplete="off"
                required
                placeholder="Yashash manzili"
                defaultValue={student?.address || ""}
              />
            </label>

            <label>
              <span>Biz haqimizda qayerdan bildingiz?</span>
              <select
                name="source"
                required
                defaultValue={student?.source || "website"}
              >
                <option value="Web syatimiz orqali">Web syatimiz orqali</option>
                <option value="Do'stim orqali">Do'stim orqali</option>
                <option value="Google dan">Google dan</option>
                <option value="Instagramdan">Instagramdan</option>
                <option value="Telegramdan">Telegramdan</option>
                <option value="Facebookdan">Facebookdan</option>
                <option value="Youtube dan">Youtube dan</option>
                <option value="Boshqa manbalar orqali">
                  Boshqa manbalar orqali
                </option>
              </select>
            </label>
            <label>
              <button>Ro'yxatga olish</button>
            </label>
          </form>
        </FormBox>
      </div>
    </div>
  );
};

// {
//     "message": "O'quvchi ma'lumotlari",
//     "variant": "success",
//     "innerData": {
//         "id": "4e1104",
//         "fullname": "Abdulbosit",
//         "parents_phone": 998999999999,
//         "student_phone": 998999976543,
//         "birthday": "2023-12-16",
//         "address": "Toshkent shahar, Yashnobod tumani",
//         "student": "he",
//         "budget": 0,
//         "groups": null,
//         "teacher": null,
//         "source": "website",
//         "learning_center_id": "b25ba4",
//         "joined_at": "2023-12-15T21:26:14.000Z",
//         "updated_at": "2023-12-15T21:26:14.000Z"
//     }
// }
