import React, { useState, useEffect } from "react";
import { Header } from "../../Components/Header/Header";
import { GoTrash } from "react-icons/go";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPaymentQuery } from "../../Context/api/payment";
import { Detail } from "../../Components/Detail/Detail";
import Skeleton from "react-loading-skeleton";
import { NumericFormat, PatternFormat } from "react-number-format";
import { dateFormat } from "../../Utility/dateFormat";
import { PiContactlessPaymentLight } from "react-icons/pi";
import { useUpdatePaymentMutation } from "../../Context/api/payment";
import { enqueueSnackbar as EnSn } from "notistack";

export const PaymentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetPaymentQuery(id);

  const payment = data?.innerData || null;
  const [value, setValue] = useState(null);

  const [updatePayment] = useUpdatePaymentMutation();

  useEffect(() => {
    setValue({
      group_id: payment?.group_id,
      payment_type: payment?.payment_type,
      amount: payment?.amount,
      grpups: payment?.student_groups,
    });
  }, [payment]);

  const handleDelete = () => {
    const confirm = window.confirm("To'lovni o'chirishni xohlaysizmi?");
    if (!confirm) return;
  };

  const handleUpdatePayment = async (e) => {
    e.preventDefault();
    const values = Object.fromEntries(new FormData(e.target));
    values.amount = values.amount.replace(/\D/g, "");
    values.student_id = payment?.student_id;

    if (!window.confirm("Taxrirlashni tasdiqlaysizmi?")) return;
    let message, variant;

    const { data, error } = await updatePayment({ id, values });
    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "To'lov muvaffaqiyatli taxrirlandi";
    variant = data?.variant || "success";
    return EnSn(message, { variant });
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">To'lov ma'lumotlari</h2>
        <div className="header-container__actions">
          <button onClick={handleDelete}>
            <GoTrash />
          </button>
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container f6">
        <Detail>
          <h3>To'lov ma'lumotlari</h3>
          <p>
            <span>O'quvchi ism familiyasi</span>
            <b>{payment?.student_name || <Skeleton width={200} />}</b>
          </p>
          <p>
            <span>Guruh nomi</span>
            <b>{payment?.group_name || <Skeleton width={200} />}</b>
          </p>
          <p>
            <span>To'lov turi</span>
            <b>{payment?.payment_type || <Skeleton width={200} />}</b>
          </p>
          <p>
            <span>To'lov summasi</span>
            <b>
              {payment?.amount ? (
                <NumericFormat
                  value={payment?.amount}
                  displayType="text"
                  thousandSeparator=" "
                  suffix=" so'm"
                  style={{ textTransform: "lowercase" }}
                />
              ) : (
                <Skeleton width={200} />
              )}
            </b>
          </p>
          <p>
            <span>To'lov sanasi</span>
            <b>
              {payment?.date ? (
                dateFormat(payment?.date, "date", "ru-RU") +
                " " +
                dateFormat(payment?.created_at, "time", "ru-RU")
              ) : (
                <Skeleton width={200} />
              )}
            </b>
          </p>
          <p>
            <span>O'quvchi telefon raqami</span>
            <b>
              {payment?.student_phone ? (
                <PatternFormat
                  format="+### ## ### ####"
                  allowEmptyFormatting
                  mask=" "
                  value={payment?.student_phone}
                  displayType="text"
                />
              ) : (
                <Skeleton width={200} />
              )}
            </b>
          </p>
        </Detail>

        <div className="page-container__box">
          <h3>To'lovni qabul qilish</h3>
          <form
            className="payment-form"
            onSubmit={handleUpdatePayment}
            style={{ display: isLoading ? "none" : "flex" }}
          >
            <label>
              <span>Gurux nomi</span>
              <select
                name="group_id"
                value={value?.group_id}
                onChange={(e) =>
                  setValue({ ...value, group_id: e.target.value })
                }
              >
                {value?.grpups?.map((group) => {
                  return (
                    <option key={group.id} value={group.id}>
                      {group.group_name}
                    </option>
                  );
                })}
              </select>
            </label>

            <label>
              <span>To'lov turi</span>
              <select
                name="payment_type"
                value={value?.payment_type}
                onChange={(e) =>
                  setValue({ ...value, payment_type: e.target.value })
                }
              >
                <option value="Naqd">Naqd</option>
                <option value="Plastik karta">Plastik karta</option>
                <option value="Pul o'tkazma">Pul o'tkazma</option>
              </select>
            </label>

            <label>
              <span>To'lov summasi</span>
              <NumericFormat
                thousandSeparator=" "
                suffix=" so'm"
                value={payment?.amount}
                name="amount"
                placeholder="250 000 so'm"
                required
                autoComplete="off"
              />
            </label>

            <label>
              <span>To'lov sanasi</span>
              <input
                type="date"
                name="date"
                placeholder="To'lov sanasi"
                required
                autoComplete="off"
                defaultValue={dateFormat(payment?.date, "date", "sv-SE")}
              />
            </label>
            <label>
              <span>To'lovni tasdiqlash</span>
              <button>
                <PiContactlessPaymentLight />
                <span>Tasdiqlash</span>
              </button>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

// {
//   id: '2fd539',
//   group_id: '0760bf',
//   payment_type: 'Plastik karta',
//   amount: 450000,
//   date: 2023-12-20T19:00:00.000Z,
//   student_id: 'b6eb8d',
//   learning_center_id: 'bc5525',
//   created_at: 2023-12-20T21:37:30.000Z,
//   updated_at: 2023-12-20T21:37:30.000Z,
//   group_name: 'HTML - CSS',
//   student_name: 'Shoxjaxon Kurganov',
//   student_phone: 998995346532,
//   student_groups: [
//      { id: '0760bf', group_name: 'HTML - CSS' },
//      { id: 'e79b07', group_name: 'IELTS' }
//   ]
// }
