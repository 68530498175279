import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";

// Loading header
export const reLoading = createSlice({
  name: "loading",
  initialState: false,
  reducers: {
    setLoading: (state, action) => {
      return action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => true
    );
    builder.addMatcher(
      (action) => action.type.endsWith("/fulfilled"),
      () => false
    );
    builder.addMatcher(
      (action) => action.type.endsWith("/rejected"),
      () => false
    );
  },
});

export const loading = reLoading.reducer;

const baseQuery = fetchBaseQuery({
  baseUrl: "https://q77zw3s2-8080.euw.devtunnels.ms/",
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // Token'ni tozalash va login sahifasiga yo'naltirish
    localStorage.clear();
    sessionStorage.clear();
    window.location.assign("/login");
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "updateTeacher",
    "updateGroup",
    "updateStudent",
    "updateContract",
    "getPayments",
  ],
  endpoints: (builder) => ({}),
});
