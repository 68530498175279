import { apiSlice } from "./api";

export const paymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // add a payment endpoint: POST => "/add/payment"
    addPayment: builder.mutation({
      query: (payment) => ({
        url: "/add/payment",
        method: "POST",
        body: payment,
      }),
      invalidatesTags: ["updatePayment"],
    }),

    // get all payments: GET "/get/payment"
    getAllPayments: builder.query({
      query: (query) => `/get/payment?${query}`,
      providesTags: ["updatePayment"],
    }),

    // get one payment: GET "/get/payment/:id"
    getPayment: builder.query({
      query: (id) => `/get/payment/${id}`,
      providesTags: ["updatePayment"],
    }),

    // get payments by student id: GET "/get/payment/student/:id"
    getPaymentsByStudentId: builder.query({
      query: (id) => `/get/payment/student/${id}`,
      providesTags: ["updatePayment"],
    }),

    // update payment: PATCH "/update/payment/:id"
    updatePayment: builder.mutation({
      query: ({ id, values }) => ({
        url: `/update/payment/${id}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["updatePayment"],
    }),
  }),
});

export const {
  useAddPaymentMutation,
  useGetAllPaymentsQuery,
  useGetPaymentQuery,
  useGetPaymentsByStudentIdQuery,
  useUpdatePaymentMutation,
} = paymentApi;
