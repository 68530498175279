import { apiSlice } from "./api";

export const teacherApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // add a teacher endpoint: POST => 'add/teacher'
    addTeacher: builder.mutation({
      query: (teacher) => ({
        url: "/add/teacher",
        method: "POST",
        body: teacher,
      }),
      invalidatesTags: ["updateTeacher"],
    }),

    // get all teachers endpoint: GET => 'get/teacher'
    getTeachers: builder.query({
      query: () => ({
        url: "/get/teacher",
        method: "GET",
      }),
      providesTags: ["updateTeacher"],
    }),

    // get a teacher endpoint: GET => 'get/teacher/:id'
    getTeacher: builder.query({
      query: (id) => ({
        url: `/get/teacher/${id}`,
        method: "GET",
      }),
      providesTags: ["updateTeacher"],
    }),

    // update a teacher endpoint: PATCH => 'update/teacher/:id'
    updateTeacher: builder.mutation({
      query: ({ id, teacher }) => ({
        url: `/update/teacher/${id}`,
        method: "PATCH",
        body: teacher,
      }),
      invalidatesTags: ["updateTeacher", "updateGroup"],
    }),

    // delete a teacher endpoint: DELETE => 'delete/teacher/:id'
    deleteTeacher: builder.mutation({
      query: (id) => ({
        url: `/delete/teacher/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["updateTeacher"],
    }),
  }),
});

export const {
  useAddTeacherMutation,
  useGetTeachersQuery,
  useGetTeacherQuery,
  useUpdateTeacherMutation,
  useDeleteTeacherMutation,
} = teacherApi;
