import React from "react";
import "./assets/Auth.css";
import logo from "./assets/logo.svg";
import { LuPhone } from "react-icons/lu";
import { PatternFormat } from "react-number-format";
import { BiLogInCircle } from "react-icons/bi";
import { enqueueSnackbar as EnSn } from "notistack";
import { useLoginMutation } from "../../Context/api/auth";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phone = e.target.phone.value.replace(/[\s+\-()]/g, "");
    const { data, error } = await login({ phone });
    let message, variant;

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }

    message = data?.message || "Tizimga hush kelibsiz";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    localStorage.setItem("exp", data?.innerData?.exp || null);
    localStorage.setItem("token", data?.innerData?.token || null);
    return navigate("/verification/login");
  };

  return (
    <div className="auth-page">
      <form className="auth-form" onSubmit={handleSubmit}>
        <img src={logo} alt="" />
        <h1>
          <span>Control Edu</span>
          <i>CRM boshqaruv tizimi</i>
        </h1>
        <label className="auth-label">
          <LuPhone />
          <PatternFormat
            format="+998 ## ### ####"
            allowEmptyFormatting
            mask="_"
            name="phone"
            placeholder="Telefon raqam"
            autoComplete="off"
            required
          />
        </label>

        <button className="auth-submit">
          <BiLogInCircle />
          <span>Tizimga kirish</span>
        </button>
      </form>
    </div>
  );
};
