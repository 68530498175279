import React from "react";
import "./NotFound.css";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";


export const NotFound = () => {
  const navigate = useNavigate();
  const back = () => navigate(-1);
  return (
    <div className="not-found">
      <h1>
        <span>{"):"} Saxifa topilmadi </span>
        <i>yoki qayta ishlanmoqda ...</i>
      </h1>
      <button onClick={back}>
        <MdArrowBackIosNew />
        <span>Orqaga qaytish</span>
      </button>
    </div>
  );
};
