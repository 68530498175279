import React from "react";
import { Header } from "../../Components/Header/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useGetTeachersQuery } from "../../Context/api/teacher";
import { useAddTeacherToGroupMutation } from "../../Context/api/group";
import { Table } from "../../Components/Table/Table";
import { PatternFormat } from "react-number-format";
import { FaPlus } from "react-icons/fa6";
import { enqueueSnackbar as EnSn } from "notistack";

export const Teacher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data = null } = useGetTeachersQuery();
  const teachers = data?.innerData || [];
  const [addTeacherToGroup] = useAddTeacherToGroupMutation();

  let count = 0;
  const handleAddTeacher = async (teacher_id) => {
    let path, action, group_id, message, variant;
    path = location?.search?.split("=").shift() || null;
    group_id = location?.search?.split("=").pop() || null;
    action = path?.includes("?add-teacher-to-group/group_id") || null;
    if (!action) return navigate(`/teacher/${teacher_id}`);

    message = "Siz ushbu o'qituvchini guruhga biriktirmoqchimisiz?";
    if (count >= 1) EnSn(message, { variant: "info" });
    path = `/teacher/${teacher_id}`;
    if (count >= 1) return navigate(path, { replace: true });
    message = "Siz O'quvchini ushbu guruhga biriktirmoqchimisiz?";
    if (!window.confirm(message)) return count++;
    const { data, error } = await addTeacherToGroup({ group_id, teacher_id });
    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }
    message = data?.message || "O'quvchi guruhga muvaffaqiyatli qo'shildi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    return navigate(-1);
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Ustozlar ro'yxati</h2>
        <div className="header-container__actions">
          <Link to="/teacher/add">
            <FaPlus />
          </Link>
        </div>
      </Header>

      <div
        className="page-container ff"
        style={{ display: !teachers?.length ? "none" : "flex" }}
      >
        <Table>
          <thead>
            <tr>
              <th>№</th>
              <th>Ism Familiya</th>
              <th>Telefon</th>
              <th>Fan</th>
              <th>Gruxlar</th>
            </tr>
          </thead>

          <tbody>
            {teachers?.map((item, index) => (
              <tr key={item.id} onClick={() => handleAddTeacher(item.id)}>
                <td style={{ textAlign: "center" }}>{index + 1}</td>
                <td>{item.fullname}</td>
                <td>
                  <PatternFormat
                    format="+### ## ### ####"
                    allowEmptyFormatting
                    displayType="text"
                    mask=" "
                    value={item.phone}
                  />
                </td>
                <td>{item.subject}</td>
                <th>{item.groups}</th>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
