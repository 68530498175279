import { Outlet, Navigate, useLocation } from "react-router-dom";

export const Auth = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("token") || null;

  if (!isLoggedIn) {
    window.location.reload();
    window.location.href = "/login";
  }

  if (isLoggedIn) return <Outlet />;
  return <Navigate to={{ pathname: "/login", state: { from: location } }} />;
};
