import React, { memo, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { NotFound } from "./Pages/NotFound/NotFound";
// Authentication pages
import { Login } from "./Pages/Authentication/Login";
import { Verification } from "./Pages/Authentication/Verification";
import { Auth } from "./Pages/Authentication/Auth";
import { Layout } from "./Components/Layout/Layout";
import { Profile } from "./Pages/Profile/Profile";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import { TimeTable } from "./Pages/TimeTable/TimeTable";

import { Teacher } from "./Pages/Teacher/Teacher";
import { AddTeacher } from "./Pages/Teacher/AddTeacher";
import { TeacherDetail } from "./Pages/Teacher/TeacherDetail";
import { UpdateTeacher } from "./Pages/Teacher/UpdateTeacher";

import { Group } from "./Pages/Group/Group";
import { AddGroup } from "./Pages/Group/AddGroup";
import { GroupDetail } from "./Pages/Group/GroupDetail";
import { UpdateGroup } from "./Pages/Group/UpdateGroup";

import { Student } from "./Pages/Student/Student";
import { AddStudent } from "./Pages/Student/AddStudent";
import { StudentDetail } from "./Pages/Student/StudentDetail";
import { UpdateStudent } from "./Pages/Student/UpdateStudent";

import { Contract } from "./Pages/Contract/Contract";
import { AddContract } from "./Pages/Contract/AddContract";
import { Payment } from "./Pages/Payment/Payment";
import { PaymentDetail } from "./Pages/Payment/PaymentDetail";

export const Router = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") navigate("/dashboard");
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/verification/:page" element={<Verification />} />
      <Route element={<Auth />}>
        <Route path="/" element={<Layout />}>
          <Route path="profile" element={<Profile />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="time-table" element={<TimeTable />} />

          <Route path="teacher">
            <Route index element={<Teacher />} />
            <Route path="add" element={<AddTeacher />} />
            <Route path=":id" element={<TeacherDetail />} />
            <Route path="update/:id" element={<UpdateTeacher />} />
          </Route>

          <Route path="group">
            <Route index element={<Group />} />
            <Route path="add" element={<AddGroup />} />
            <Route path=":id" element={<GroupDetail />} />
            <Route path="update/:id" element={<UpdateGroup />} />
          </Route>

          <Route path="student">
            <Route index element={<Student />} />
            <Route path="add" element={<AddStudent />} />
            <Route path=":id" element={<StudentDetail />} />
            <Route path="update/:id" element={<UpdateStudent />} />
          </Route>

          <Route path="contract">
            <Route index element={<Contract />} />
            <Route path=":group_id/:student_id" element={<AddContract />} />
          </Route>

          <Route path="payment">
            <Route index element={<Payment />} />
            <Route path=":id" element={<PaymentDetail />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
});
