import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header/Header";
import { FormBox } from "../../Components/Form/Form";
import { TbSpacingHorizontal } from "react-icons/tb";
import { useAddGroupMutation } from "../../Context/api/group";
import { enqueueSnackbar as EnSn } from "notistack";
import { MdOutlineArrowBackIos } from "react-icons/md";

export const AddGroup = () => {
  const navigate = useNavigate();
  const [days, setDays] = useState([]);
  const [addGroup] = useAddGroupMutation();

  function handleDays(e) {
    const value = Number(e.target.value);
    if (days.includes(value)) {
      setDays(days.filter((day) => day !== value));
    } else {
      setDays([...days, value]);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirm = window.confirm("Siz yangi guruh ochmoqchimisiz?");
    if (!confirm) return;
    const values = Object.fromEntries(new FormData(e.target));
    const group = { ...values, days };
    group.time = `${group.started_time} - ${group.finished_time}`;
    delete group.started_time;
    delete group.finished_time;
    const { data, error } = await addGroup(group);
    let message, variant;
    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      return EnSn(message, { variant });
    }
    message = data?.message || "Guruh muvaffaqiyatli ochildi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    navigate(-1);
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Grux ochish</h2>
        <div className="header-container__actions">
          <button onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIos />
          </button>
        </div>
      </Header>

      <div className="page-container ff">
        <FormBox>
          <form onSubmit={handleSubmit}>
            <label>
              <span>Guruh nomi</span>
              <input
                type="text"
                name="group_name"
                required
                autoComplete="off"
                autoFocus
                placeholder="Guruh nomi"
              />
            </label>
            <label>
              <span>Guruh haqida</span>
              <textarea
                name="description"
                required
                autoComplete="off"
                placeholder="Guruh haqida qisqacha ma'lumot"
              />
            </label>
            <label>
              <span>Dars vaqti</span>
              <div className="form-time-group">
                <input
                  type="time"
                  name="started_time"
                  required
                  autoComplete="off"
                  placeholder="Boshlanish vaqti"
                  defaultValue={"08:00"}
                />
                <TbSpacingHorizontal />
                <input
                  type="time"
                  name="finished_time"
                  required
                  autoComplete="off"
                  placeholder="Tugash vaqti"
                  defaultValue={"10:00"}
                />
              </div>
            </label>
            <div className="form-group-days">
              <span>Dars kunlari</span>

              {daysData.map((day) => {
                return (
                  <label
                    className={days.includes(day.id) ? "day active" : "day"}
                    key={day.id}
                  >
                    <input
                      type="checkbox"
                      value={day.id}
                      onChange={handleDays}
                    />
                    <span>{day.name}</span>
                  </label>
                );
              })}
            </div>

            <label>
              <span>Dars o'tiladigan xona</span>
              <input
                type="text"
                name="room"
                required
                autoComplete="off"
                placeholder="Masalan: 1-qavat, 3-xona"
              />
            </label>
            <label className="form-group-date">
              <span>O'quv kurs davomiyligi</span>
              <div className="form-time-group">
                <input
                  type="date"
                  name="started_at"
                  required
                  autoComplete="off"
                  placeholder="Boshlanish vaqti"
                  defaultValue={new Date().toLocaleDateString("sv-SE")}
                />

                <TbSpacingHorizontal />

                <input
                  type="date"
                  name="finished_at"
                  required
                  autoComplete="off"
                  placeholder="Tugash vaqti"
                  defaultValue={new Date(+new Date() + 15799000000)
                    .toLocaleDateString("sv-SE")
                    .replaceAll(".", "-")}
                />
              </div>
            </label>

            <label>
              <button type="submit">Saqlash</button>
            </label>
          </form>
        </FormBox>
      </div>
    </div>
  );
};

const daysData = [
  { id: 1, name: "Du" },
  { id: 2, name: "Se" },
  { id: 3, name: "Cho" },
  { id: 4, name: "Pa" },
  { id: 5, name: "Ju" },
  { id: 6, name: "Sha" },
  { id: 0, name: "Ya" },
];

// const group = {
//   group_name: "Group 1",
//   description: "This is a group description",
//   time: "10:00 - 12:00",
//   days: [1, 3, 5],
//   room: "Room 1",
//   started_at: "2021-01-01",
//   finished_at: "2021-01-01",
// };
