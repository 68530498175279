import React from "react";
import { Header } from "../../Components/Header/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useGetAllGroupsQuery } from "../../Context/api/group";
import { Table } from "../../Components/Table/Table";
import { FaPlus } from "react-icons/fa6";
import { week } from "../../Utility/week";
import { useAddStudentToGroupMutation } from "../../Context/api/student";
import { enqueueSnackbar as EnSn } from "notistack";

export const Group = () => {
  const { data = null } = useGetAllGroupsQuery();
  const groups = data?.innerData || [];

  const navigate = useNavigate();
  const location = useLocation();
  const [addStudentToGroup] = useAddStudentToGroupMutation();

  let count = 0;
  const handelAddStudent = async (id) => {
    let message, variant, path, student_id;
    path = location?.search?.split("=").shift() || null;
    student_id = location?.search?.split("=").pop() || null;
    const condition = !path?.includes("add/student/to/group");
    if (condition) return navigate(`/group/${id}`);
    const confirm = window.confirm("Siz o'quvchini guruhga qo'shmoqchimisiz?");

    message = "Siz O'quvchini ushbu guruhga biriktirmoqchimisiz?";
    if (count >= 1) EnSn(message, { variant: "info" });
    if (count >= 1) return navigate(`/group/${id}`, { replace: true });

    if (!confirm) return count++;
    const insert = { group_id: id, student_id };
    const { data, error } = await addStudentToGroup(insert);

    if (error) {
      message = error?.data?.message || "Xatolik yuz berdi";
      variant = error?.data?.variant || "warning";
      EnSn(message, { variant });
      return navigate(-1);
    }

    message = data?.message || "O'quvchi guruhga qo'shildi";
    variant = data?.variant || "success";
    EnSn(message, { variant });
    navigate(-1);
  };

  return (
    <div className="page">
      <Header>
        <h2 className="header-container__title">Guruhlar ro'yxati</h2>

        <div className="header-container__actions">
          <Link to="add" title="Yangi guruh ochish">
            <FaPlus />
          </Link>
        </div>
      </Header>

      <div
        className="page-container ff"
        style={{ display: !groups?.length ? "none" : "flex" }}
      >
        <Table>
          <thead>
            <tr>
              <th>№</th>
              <th>Guruh nomi</th>
              <th>Dars vaqti</th>
              <th>Dars kunlari</th>
              <th>O'qituvchi</th>
              <th>O'quvchilar</th>
              <th>Xona</th>
            </tr>
          </thead>
          <tbody>
            {groups?.map((group, index) => {
              return (
                <tr key={group?.id} onClick={() => handelAddStudent(group?.id)}>
                  <td style={{ textAlign: "center" }}>
                    {groups?.length - index}
                  </td>
                  <td>{group?.group_name}</td>
                  <td>{group?.time}</td>
                  <td>{week(group?.days)}</td>
                  <td>{group?.teacher || "Yo'q"}</td>
                  <td>{group?.students}</td>
                  <td>{group?.room}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
