import React from "react";
import "./Dashboard.css";
import { Chart } from "react-google-charts";

import { Link } from "react-router-dom";
import { PiStudent } from "react-icons/pi";
import { LiaUserTieSolid } from "react-icons/lia";
import { VscGroupByRefType } from "react-icons/vsc";
import { AiOutlineMessage } from "react-icons/ai";
import { useGetTeachersQuery } from "../../Context/api/teacher";
import { useGetAllGroupsQuery } from "../../Context/api/group";
import { useGetStudentsQuery } from "../../Context/api/student";

const contract = [
  ["", "Ro'yhatdan o'tgan", "Shartnoma", "Bekor qilingan"],
  ["11.2023", 454, 400, 54],
  ["12.2023", 224, 196, 28],
  ["01.2024", 157, 137, 20],
  ["02.2024", 139, 121, 18],
];

const dataPayments = [
  ["Task", "Hours per Day"],
  ["Bajarildi", 121],
  ["Qoldi", 20],
];

export const Dashboard = () => {
  const { data: dataTeachers = null } = useGetTeachersQuery();
  const { data: dataGroup = null } = useGetAllGroupsQuery();
  const { data: dataStudents = null } = useGetStudentsQuery();

  return (
    <div className="dashboard">
      <Link to="/teacher" className="dashboard-info4x">
        <h3 className="dashboard-info4x__title">
          <span>Xodimlar</span>
          <b>{dataTeachers?.innerData?.length || 0}</b>
        </h3>
        <LiaUserTieSolid />
      </Link>

      <Link to="/group" className="dashboard-info4x">
        <h3 className="dashboard-info4x__title">
          <span>Guruhlar</span>
          <b>{dataGroup?.innerData?.length || 0}</b>
        </h3>
        <VscGroupByRefType />
      </Link>

      <Link to="/student" className="dashboard-info4x">
        <h3 className="dashboard-info4x__title">
          <span>O'quvchilar</span>
          <b>{dataStudents?.innerData?.length || 0}</b>
        </h3>
        <PiStudent />
      </Link>

      <Link to="/messages" className="dashboard-info4x">
        <h3 className="dashboard-info4x__title">
          <span>Xabarlar</span>
          <b>5</b>
        </h3>
        <AiOutlineMessage />
      </Link>

      <div className="dashboard-info2x">
        <div className="dashboard-info2x__header">
          <p className="dashboard-info2x__title">
            <span>To'lovlar xolati</span>
            <i>O'quvchilar: 236</i>
          </p>

          <select name="month" className="dashboard-info2x__select">
            <option value="11.2023">11.2023</option>
            <option value="12.2023">12.2023</option>
            <option value="01.2024">01.2024</option>
            <option value="02.2024">02.2024</option>
            <option value="03.2024">03.2024</option>
            <option value="04.2024">04.2024</option>
            <option value="05.2024">05.2024</option>
          </select>
        </div>

        <Chart
          width="100%"
          height="100%"
          style={{ marginTop: "-20px" }}
          spreadSheetQueryParameters={{ headerRow: true }}
          chartLanguage="uz"
          chartType="PieChart"
          data={dataPayments}
          options={{
            pieHole: 0.4,
            is3D: false,
            backgroundColor: "#fff",
            legendTextStyle: {
              fontSize: 14,
            },
          }}
        />
      </div>
      <div className="dashboard-info2x">
        <Chart
          chartType="Bar"
          width="100%"
          height="100%"
          data={contract}
          options={{
            chart: {
              title: "Oxirgi 1 yillik statistika",
              subtitle: "12.2022 - 12.2023 davomidagi shartnomalar soni",
            },
            legend: { position: "none" },
          }}
        />
      </div>

      <div className="dashboard-info2x">
        <h2 className="dashboard-info2x__title__news">
          <span>Dars jadvali - {new Date().toLocaleDateString("ru-RU")}</span>
          <Link to="/schedule">Dars jadvalini ko'rish</Link>
        </h2>

        <div className="dashboard-info2x__schedule">
          {schedule.map((item) => {
            return (
              <div className="dashboard-info2x__schedule-item" key={item.id}>
                <p className="dashboard-info2x__schedule-item__group">
                  {item.group} <span>{item.room}</span>
                </p>
                <p className="dashboard-info2x__schedule-item__text">
                  {item.time}
                </p>
                <p className="dashboard-info2x__schedule-item__text">
                  {item.subject}
                </p>
                <p className="dashboard-info2x__schedule-item__text">
                  {item.teacher}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="dashboard-info2x">
        <h2 className="dashboard-info2x__title__news">
          <span>Control Edu - Palatformasidagi yangiliklar</span>
          <a
            href="https://t.me/controledubot"
            target="_blank"
            rel="noopener noreferrer"
          >
            Batafsil
          </a>
        </h2>

        <div className="dashboard-info2x__news">
          {news.map((item) => {
            return (
              <a
                key={item.id}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="dashboard-info2x__news-item"
              >
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const news = [
  {
    id: 3,
    title: "Control Edu - 1.0.0 versiyasi chiqdi (11.30.2023)",
    text: "Control Edu - 1.0.0 versiyasi chiqdi. Bu versiyada o'quvchilar ro'yhati, guruhlar, xodimlar va xabarlar bo'limlari mavjud.",
    link: "https://t.me/controledubot",
  },

  {
    id: 2,
    title: "Control Edu - 1.0.0 versiyasi chiqdi (11.23.2023)",
    text: "Control Edu - 1.0.0 versiyasi chiqdi. Bu versiyada o'quvchilar ro'yhati, guruhlar, xodimlar va xabarlar bo'limlari mavjud.",
    link: "https://t.me/controledubot",
  },
  {
    id: 1,
    title: "Control Edu - 1.0.0 versiyasi chiqdi (11.10.2023)",
    text: "Control Edu - 1.0.0 versiyasi chiqdi. Bu versiyada o'quvchilar ro'yhati, guruhlar, xodimlar va xabarlar bo'limlari mavjud.",
    link: "https://t.me/controledubot",
  },
  {
    id: 4,
    title: "Control Edu - 1.0.0 versiyasi chiqdi (11.30.2023)",
    text: "Control Edu - 1.0.0 versiyasi chiqdi. Bu versiyada o'quvchilar ro'yhati, guruhlar, xodimlar va xabarlar bo'limlari mavjud.",
    link: "https://t.me/controledubot",
  },
  {
    id: 5,
    title: "Control Edu - 1.0.0 versiyasi chiqdi (11.30.2023)",
    text: "Control Edu - 1.0.0 versiyasi chiqdi. Bu versiyada o'quvchilar ro'yhati, guruhlar, xodimlar va xabarlar bo'limlari mavjud.",
    link: "https://t.me/controledubot",
  },
];

const schedule = [
  {
    id: 1,
    group: "1. Guruh",
    time: "09:00 - 10:00",
    subject: "Matematika",
    teacher: "Abdusamadov Abdusamad",
    room: "1/1",
  },
  {
    id: 2,
    group: "2. Guruh",
    time: "10:00 - 11:00",
    subject: "Ona tili",
    teacher: "Abdusamadov Abdusamad",
    room: "1/2",
  },
  {
    id: 3,
    group: "3. Guruh",
    time: "11:00 - 12:00",
    subject: "Matematika",
    teacher: "Abdusamadov Abdusamad",
    room: "1/3",
  },
  {
    id: 4,
    group: "4. Guruh",
    time: "12:00 - 13:00",
    subject: "Ona tili",
    teacher: "Abdusamadov Abdusamad",
    room: "1/4",
  },
  {
    id: 5,
    group: "5. Guruh",
    time: "13:00 - 14:00",
    subject: "Matematika",
    teacher: "Abdusamadov Abdusamad",
    room: "1/5",
  },
];
